import React from 'react'
import Markdown from 'markdown-to-jsx';
import Img from './Img';
import Points from './Points';
import Pre from './Pre'
import Table from './Table';

const MarkdownCore = ({ textForMarkdown, isDualMonitor, refTextDual, paddingTop }) => {
  const P = ({ children, ...props }) => {
    const handleDoubleClick = () => {
      if ( isDualMonitor ) {
        refTextDual.current.selectionStart = refTextDual.current.value.indexOf(children[0])
        refTextDual.current.selectionEnd = refTextDual.current.value.indexOf(children[0]) + children[0].length
        refTextDual.current.focus()
        refTextDual.current.scrollTop = refTextDual.current.scrollHeight * Math.max(refTextDual.current.selectionStart - 500, 0) / refTextDual.current.value.length
      }
    }
    return (
      <p {...props} onDoubleClick={ handleDoubleClick } >
        {children}
      </p>
    )
  }

  const options = {
    overrides: {
        h1 : { props: { className: `w-custom-wv lg:w-full max-w-2xl mx-auto leading-10 ${ !!paddingTop ? 'pt-12' : 'mt-12' } text-4xl tracking-tight text-gray-900 font-semibold` } },
        h2 : { props: { className: 'w-custom-wv lg:w-full max-w-2xl mx-auto leading-8 mt-2 text-2xl text-gray-900 -mb-2 font-light' } },

        p  : { props: { className: 'w-custom-wv lg:w-full mx-auto max-w-2xl mt-5 tracking-custom text-xl -mb-2 break-normal text-gray-800 block leading-8' },
              component: P },
        ul : { props: { className: 'list-disc ml-10' } },
        li : { props: { className: 'w-custom-wv lg:w-full max-w-2xl mx-auto mt-5 tracking-custom text-xl -mb-2 break-normal text-gray-800 leading-8' } },
        ol : { props: { className: 'w-custom-wv lg:w-full max-w-2xl mx-auto list-decimal' } },
        a  : { props: { className: 'underline tracking-custom' } },
        img: { component: Img },
        hr : { component: Points },

        pre: { component: Pre },
        tr : { props: { className: 'bg-gray-100 hover:bg-gray-300' } },
        th : { props: { className: 'px-4 py-2 hover:bg-gray-300' } },
        td : { props: { className: 'border px-4 py-2 hover:bg-gray-300' } },
        thead: { props: { className: ' ' } },
        tbody: { props: { className: ' ' } },
        table: { component: Table,  props: { className: 'table-auto mt-4' } },

        strong: { props : { className: 'font-serif' } },

        figcaption: { props: { className: 'text-center text-gray-700' } },

        blockquote: { props: { className: 'mt-2 p-4 text-xl italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote' } },
    },
  }
  return (
    <Markdown 
      options={ options }
      >
        { textForMarkdown }
      </Markdown>
  )
}

// eslint-disable-next-line no-unused-vars
const compilerTailwindsCSS = () => {
  return (
    <div className={`leading-10 mt-12 text-4xl tracking-tight 
font-semibold
mt-2 text-2xl text-gray-900 font-light
block 
list-disc ml-10
mt-5 -mb-2 break-normal text-gray-800 leading-8
w-custom-wv lg:w-full max-w-2xl mx-auto list-decimal
underline tracking-custom
mt-8
bg-gray-100
hover:bg-gray-300
border px-4 py-2

table-auto mt-4
font-serif
text-center text-gray-700
mt-2 p-4 text-xl italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote

bg-red-600
text-red-100
bg-teal-600
text-teal-100
`}>
    </div>
    // NO OLVIDAR DE COPIAR LOS CSS DE TITLESECTION!
  )
}

// eslint-disable-next-line no-unused-vars
const compilerTailwindsCSSPost = () => {
  return (
    <div className={`text-green-600 text-red-600 text-yellow-600 lg:-mx-32
inline-block align-top text-sm
`}>
    </div>
    // NUNCA ELIMINAR!! son de los posts
  )
}



export default MarkdownCore

