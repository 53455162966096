import React from 'react'
import { useField } from 'formik';

const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className='mb-4'>
      <label className='block mb-2 text-sm font-bold text-gray-700'>
        {label}
      </label>
      <div className='flex justify-between'>
        <div className='flex flex-col items-center justify-center'>
          <div>
            <input {...props} {...field} type='radio' id='0.32mm' value='0.32mm'/>
            <label className='text-gray-700 bg-white focus:outline-none focus:shadow-outline' htmlFor='0.32mm'> 0.32mm</label>
          </div>
          <span className='text-xs text-center text-gray-500' >Rápido con definición normal</span>
        </div>

        <div className='flex flex-col items-center justify-center'>
          <div>
            <input {...props} {...field} type='radio' id='0.2mm' value='0.2mm'/>
            <label className='text-gray-700 bg-white focus:outline-none focus:shadow-outline' htmlFor='0.2mm'> 0.2mm</label>
          </div>
          <span className='text-xs text-center text-gray-500' >Lento con alta definición</span>
        </div>

        <div className='flex flex-col items-center justify-center'>
          <div>
            <input {...props} {...field} type='radio' id='0.12mm' value='0.12mm'/>
            <label className='text-gray-700 bg-white focus:outline-none focus:shadow-outline' htmlFor='0.12mm'> 0.12mm</label>
          </div>
          <span className='text-xs text-center text-gray-500' >Muy lento con muy alta definición.</span>
        </div>
      </div>
      <div className={`inline-block text-red-500 animate__animated animate__fast ${(meta.touched && meta.error) ? 'animate__bounceIn' : 'hidden'}`}>
        {meta.error}
      </div>
    </div>
  )
}

export default SelectInput
