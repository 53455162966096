import React from 'react'
import { ReactComponent as LoadingIcon } from '../../imgs/loading-2.svg'

const LoadMini = () => {
  return (
    <div className='flex justify-center py-16 bg-gray-100'>
      <div className='p-8 bg-gray-100'>
        <span className='block text-xl text-center text-gray-800 break-normal tracking-custom'>Cargando... 
          <LoadingIcon className='inline w-5 h-5 ml-3 text-gray-800 fill-current animate-spin' /> 
        </span>
      </div>
    </div>
  )
}

export default LoadMini
