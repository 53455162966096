import { types } from "../types"

export const blogReducer = (state={}, action) => {

  switch ( action.type ) {
    case types.blogReader:
      return {
        blogs: action.payload
      }
    case types.blogError:
      return {
        error: 'Permisos insuficientes'
      }
    default:
      return state
  }
}