import React, { useState } from 'react'
import * as Yup from 'yup';
import { Formik, Form }    from 'formik';
import moment from 'moment'
import 'moment/locale/es';

import { db } from '../../../Firebase/firebase'
import TextInput from '../../../Cotizacion/TextInput'
import { ReactComponent as LoadingIcon }    from '../../../../imgs/loading-2.svg'
import AcceptTerm from './AcceptTerm';

const Newsletter = ({ insidePost=false }) => {
  const [loading, setLoading] = useState(false)
  const [suscrito, setSuscrito] = useState(false)

  const onSubmitForm = (value, resetForm) => {
    setLoading(true)

    const id = moment().format('YYYY-MM-DD_kk:mm:ss')
    db.collection('newsletter').doc( id ).set({
      email: value.email
    })
      .then(() => {
        setLoading(false)
        setSuscrito(true)
        resetForm()
      })
  }

  const yupBase = {
    permissions: Yup.boolean()
      .oneOf([true], 'Porfavor acepte las condiciones'),
    email: Yup.string()
      .email('Correo inválido.')
      .required('Porfavor ingrese su correo.'),
  }
  const yup = Yup.object({ ...yupBase })

  const INIT_VALUES = {
    permissions: false,
    email: ''
  }
  return (
    <div className={`flex flex-col w-full mb-16 bg-gray-200 rounded-lg shadow-lg ${!insidePost && 'lg:w-2/5 lg:mt-16 lg:h-full'}`}>
      <div className='mx-2 my-1'>

        <Formik initialValues={ INIT_VALUES }
          validationSchema={ yup }
          onSubmit={ (value, { resetForm }) => { 
            onSubmitForm(value, resetForm)
          }
            // onSubmitForm( value, setSubmitting, setStatus, resetForm, setLoading, setErrorSubmit, resetFiles, setSuccess, INIT_VALUES, fieldFiles )
          }>
            <Form>
              <p className='mt-5 text-xl font-bold leading-7 tracking-tight text-gray-900'><strong>
                ¿Quiere saber más y recibir información sobre nuevas publicaciones?
              </strong></p>
              <p className='mt-5 mb-4 text-gray-700'>
                Mantengámonos en contacto y deje su correo electrónico
              </p>
              <TextInput label='' name='email' type='email' placeholder='ejemplo@ejemplo.com' />
              
              <AcceptTerm name='permissions' />
              
              <div className='flex justify-center' >
              <button 
              className='flex justify-between w-full px-4 py-3 my-4 bg-blue-700 rounded-lg shadow-md hover:bg-blue-600 focus:shadow-outline' 
                      type='submit'>
                <span className='mx-auto font-semibold text-center text-white'>{!suscrito ? loading? 'Suscribiendo...' : 'Suscribirme!' : 'Suscrito!' }</span>
                <LoadingIcon className={`w-5 h-5 ml-3 text-gray-100 fill-current animate-spin ${loading ? 'block' : 'hidden'}`} />
              </button>
            </div>

            </Form>


        </Formik>
      </div>

    </div>
  )
}

export default Newsletter
