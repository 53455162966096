import React from 'react'
import { useField } from 'formik';

const TextAreaInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div className='mb-4'>
      <label className='block mb-2 text-sm font-bold text-gray-700'>
        {label}
      </label>
      <textarea  className={`w-full px-3 py-2 mx-auto leading-tight text-gray-700 border rounded 
                          shadow appearance-none focus:outline-none focus:shadow-outline 
                          ${(meta.touched && meta.error) && 'border border-red-500'}`} 
              {...field} {...props}/>
      <div className={`inline-block text-red-500 animate__animated animate__fast ${(meta.touched && meta.error) ? 'animate__bounceIn' : 'hidden'}`}>
        {meta.error}
      </div>
    </div>
  )
}

export default TextAreaInput
