import React from 'react'
import { ReactComponent as SuccessIcon }    from '../../imgs/success.svg'
import { ReactComponent as ErrorIcon }    from '../../imgs/error.svg'

const Alert = ({ isError=false }) => {
  return (
    <div className='fixed inset-x-0 bottom-0 z-30 max-w-md mx-auto'>
      <div className={`px-4 py-3 ${!isError ? 'text-green-900 border-green-500 bg-green-100' : 'text-red-900 border-red-500 bg-red-100'}  border-t-4  rounded-b shadow-md text`} role='alert'>
        <div className='flex'>
          <div className='py-1'>
            {
              !isError ?
                <SuccessIcon className='w-6 h-6 mr-4 text-green-500 fill-current' />
              :
                <ErrorIcon className='w-6 h-6 mr-4 text-red-500 fill-current' />
            }
          </div>
          <div>
            
            <p className='font-bold'>{!isError ? 'Su mensaje se ha enviado' : 'Ha ocurrido un error al enviar el mensaje'}</p>
            <p className='text-sm'>{!isError ? 'Nuestro equipo le responderá lo antes posible.' : 'Porfavor verifique su conexión a internet'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Alert
