import React from 'react'

const Table = ({ children, ...props }) => {
  return (
    <div className='flex justify-center'>
      <table  {...props} >
        { children }
      </table>
    </div>
  )
}

export default Table
