import React from 'react'

const Points = () => {
  return (
    <div className='flex justify-center mt-8' >
      <span className='w-4 h-4 mx-1'>&bull;</span>
      <span className='w-4 h-4 mx-1'>&bull;</span>
      <span className='w-4 h-4 mx-1'>&bull;</span>
    </div>
  )
}

export default Points
