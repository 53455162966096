import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, 
      setSaveText: props.setSaveText,
      setTextForMarkdown: props.setTextForMarkdown,
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    let setTextForMarkdown = this.state.setTextForMarkdown
    this.state.setSaveText( (safeText) => {
      setTextForMarkdown( safeText )
      return safeText
    } )
    this.state.setTextForMarkdown(this.state.textForMarkdown)

  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary
