/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { actionGetBlogReader } from "../../../../actions/actionBlog"

export default () => {
  const FORMAT = 'MMM DD, [de] YYYY'
  const blogs = useSelector(state => state.blogs)
  const dispatch = useDispatch()

  const params = useParams()

  const [blogLocal, setBlogLocal] = useState({})
  const [existsPost, setExistsPost] = useState(true)

  const preprocessTitleToUrl = (title) => {
    title = title.replaceAll(' ', '-')
    title = title.replaceAll('á', 'a')
    title = title.replaceAll('é', 'e')
    title = title.replaceAll('í', 'i')
    title = title.replaceAll('ó', 'o')
    title = title.replaceAll('ú', 'u')
    title = title.replaceAll('ñ', 'n')
    title = title.toLowerCase()
    return title
  }

  useEffect(() => {
    if (Object.keys( blogs ).length === 0){
      dispatch( actionGetBlogReader(true) )
    }
  }, [blogs, dispatch])


  useEffect(() => {
    if (Object.keys( blogLocal ).length === 0 && Object.keys( blogs ).length !== 0 ){
      for (let [i, blog] of blogs.blogs.entries()) {
        if (preprocessTitleToUrl( blog.title ) === params.title) {

          setBlogLocal({ 
            ...blog,
            index: i
          })
        }
      }
      if (Object.keys( blogLocal ).length === 0) {
        setExistsPost( false )
      }
    }
  }, [blogs, params, blogLocal])

  const preprocessText = (text) => {
    return text.replaceAll('/n', '\n')
  }

  
  return { blogLocal, params, FORMAT, preprocessText, existsPost }
}