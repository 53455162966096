import axios from 'axios'

const onSubmitForm = ( value, setSubmitting, setStatus, resetForm, setLoading, setErrorSubmit, resetFiles, setSuccess, INIT_VALUES, fieldFiles, isFace3DMesh, countFaces ) => {
  setLoading(true)
  let values = {...value, fieldFiles}

  let mail = new FormData()
  mail.append('name', values.firstName)
  mail.append('lastName', values.lastName)
  mail.append('correo', values.email)
  mail.append('phone', values.phone)
  mail.append('message', values.message)
  mail.append('isFace3DMesh', isFace3DMesh !== undefined)
  if (values.fieldFiles.values.length > 0){
    mail.append('color', values.material)
    mail.append('quality', values.quality)
    mail.append('density', values.infill)
    if ( isFace3DMesh === undefined ) {
      for (let file of values.fieldFiles.values){
        mail.append(file.name, file)
      }
    } else {
      let namesFiles = ''
      for (let [index, name] of fieldFiles.names.entries()) {
        let urlFile = fieldFiles.values[index]
        namesFiles = namesFiles + ';-;' + name
        mail.append(name, urlFile)
      }
      mail.append('namesFiles', namesFiles)
      mail.append('countFaces', countFaces.reduce((a, b) => a + b, 0))
    }
  }

  
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  axios.post('https://us-central1-ocular-3d.cloudfunctions.net/mail_sender', mail, config)
  // axios.post('http://0.0.0.0:8080', mail, config)
  .then(function (response) {
      setSuccess(true)
      setLoading(false)
      setStatus({success: true})
      setSubmitting(true)
      resetForm({INIT_VALUES})
      resetFiles()
      setTimeout(() => {
        setSuccess(false)
      }, 10000)
    })
    .catch(function (error) {
      setLoading(false)
      setErrorSubmit(true)
      setStatus({success: false})
      setTimeout(() => {
        setErrorSubmit(false)
      }, 10000)
    })

}

export default onSubmitForm
