const getFirstImage = (text) => {
  const numToSlice = text.search('https')
  const urlFirst = text.slice(numToSlice)
  const url = urlFirst.split(' ')[0]

  const numToSliceAlt = urlFirst.search("'")
  const contentAlt = urlFirst.slice(numToSliceAlt + 1)
  const numToSliceAltEnd = contentAlt.search("'")
  const alt = contentAlt.slice(0, numToSliceAltEnd)
  return { url, alt }
}

const preprocessTitleToUrl = (title) => {
  title = title.replaceAll(' ', '-')
  title = title.replaceAll('á', 'a')
  title = title.replaceAll('é', 'e')
  title = title.replaceAll('í', 'i')
  title = title.replaceAll('ó', 'o')
  title = title.replaceAll('ú', 'u')
  title = title.replaceAll('ñ', 'n')
  title = title.toLowerCase()
  return title
}

export { getFirstImage, preprocessTitleToUrl }