import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/es';

import BlogPost from './BlogPost'
import { getFirstImage, preprocessTitleToUrl } from '../BlogAdmin/ActualPost/getFunctions';
import { actionGetBlogReader } from '../../../actions/actionBlog';
import getTimeOfRead from '../getTimeOfRead';
import Newsletter from './Newsletter';

const Blog = () => {
  const FORMAT = 'MMM DD, [de] YYYY'
  const blogs = useSelector(state => state.blogs)
  const dispatch = useDispatch()

  useEffect(() => {
    if ( Object.keys( blogs ).length === 0 ) {
      dispatch( actionGetBlogReader(true) )
    }
  }, [blogs, dispatch])

  return (
    <div className='container max-w-screen-xl mx-auto'>
      <div className='mx-4 md:mx-0'>
        <div className='flex flex-col w-full max-w-2xl mx-auto lg:max-w-4xl lg:flex-row lg:space-x-8'>
          <div className='w-full max-w-2xl mx-auto'>
            <h1 className='mb-4 text-3xl font-semibold text-center text-yellow-600' >Noticias sobre la impresón 3D</h1>
            {
              Object.keys( blogs ).length !== 0 ? 
              !blogs.error &&
              blogs.blogs.map( (blog) => {
                const { url, alt } = getFirstImage( blog.text )
                return(
                  <div key={ blog.title } className='flex flex-col mt-4 mb-16'>
                    <Link to={ `/noticias/${preprocessTitleToUrl( blog.title )}` }>
                      <img className='object-cover w-full h-56 cursor-pointer' src={ url } alt={ alt } title={ alt } />
                    </Link>
                    <Link to={ `/noticias/${preprocessTitleToUrl( blog.title )}` }>
                      <p className='mt-5 text-xl font-bold leading-5 tracking-tight text-gray-900'> { blog.title } </p>
                    </Link>
                    <p className='block mt-2 text-gray-700'> { blog.subTitle } </p>
                    <p className='block mt-2 text-gray-500'>{ moment(blog.date).format(FORMAT) } &bull; { getTimeOfRead(blog.text) } min de lectura</p>
                  </div>
                )
              } )
                :
              [...Array(5).keys()].map((v) => (
                <div key={ v } className='flex flex-col w-full mt-4 mb-16 w animate-pulse'>
                  <div className='w-full h-56 bg-gray-400'></div>
                  <div className='w-full h-5 mt-5 bg-gray-400' ></div>
                  <div className='w-full h-5 mt-5 bg-gray-300' ></div>
                  <div className='w-full h-5 mt-5 bg-gray-300' ></div>
                </div>
              ))
              
            }

          </div>
          <Newsletter />
        </div>
      </div>
    </div>
  )
}

export { BlogPost }

export default Blog
