import { types } from "../types"

export const authReducer = (state={}, action) => {

  switch ( action.type ) {
    case types.login:
      return {
        uid: action.payload.uid,
        name: action.payload.displayName,
        photoUrl: action.payload.photoUrl,
        email: action.payload.email,
      }
    case types.logout:
        return { }
    default:
      return state
  }
}