import { types } from "../types"

const STATE_INIT = [false]

export const instagramReducer = (state=STATE_INIT, action) => {
  switch (action.type) {
    case types.setInstagram:
      return action.payload
  
    default:
      return state
  }
}