import React from 'react'
import { Field, useField } from 'formik'

const AcceptTerm = ({ ...props }) => {
  const [, meta] = useField(props);
  return (
    <label className='mt-5 text-sm font-light text-gray-600'>
      <Field type='checkbox' name='permissions' className='mr-2' />
      Al suscribirme, permito que Ocular 3D guarde mi dirección de correo electrónico para 
      enviarme noticias y actualizaciones. Podrás darte de baja en cualquier momento. <strong className='font-bold '>¡No daremos tus datos a nadie!</strong>
      <div className={`text-red-500 animate__animated animate__fast ${(meta.touched && meta.error) ? 'animate__bounceIn block' : 'hidden'}`}>
        {meta.error}
      </div>
    </label>
  )
}

export default AcceptTerm
