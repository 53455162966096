import React from 'react'

// import { ReactComponent as Machine }      from '../../imgs/3dPrinter_machine.svg'
// import { ReactComponent as Machine2 }      from '../../imgs/3dPrinter_machine-2.svg'
import { ReactComponent as Machine3 }      from '../../imgs/3dPrinter_machine-3.svg'
// import { ReactComponent as Machine4 }      from '../../imgs/3dPrinter_machine-4.svg'
import { ReactComponent as Print }        from '../../imgs/3dPrinter_print-2.svg'
import { ReactComponent as Presentation } from '../../imgs/3dPrinter_presentation-3.svg'
import { ReactComponent as Printer } from '../../imgs/printer.svg'
import { ReactComponent as Filament } from '../../imgs/filament.svg'

import Card from './Card'

const Services = () => {

  const text = {
    subTitle: 'Nuestros servicios te brindarán de gran personalización para tu proyecto, entregándote la idea que tú imaginas.',
    machine: {
      title: 'Venta de maquinaria',
      details: 'Impresoras de primer nivel, con la versatilidad de cambios modulares'
    },
    print: {
      title: 'Servicio de impresion 3D',
      details: 'Producción de diseño de pequeña escala de diseños personalizados'
    },
    presentation: {
      title: 'Capacitaciones y charlas',
      details: 'Capacitaciones y charlas con aprendizaje técnico sobre innovación y emprendimiento'
    }
  }

  return (
    <section className='mx-3 my-8'>
      <h2 className='mb-4 text-3xl font-semibold text-center text-blue-700'>Conoce los servicios</h2>
      <h3 className='text-center text-gray-700 text-md' >{ text.subTitle }</h3>
      <div className='lg:flex lg:space-x-4 lg:mt-20 lg:justify-between'>
        <Card Img={ Machine3 }      title={ text.machine.title }      details={ text.machine.details } />
        <Card Img={ Print }         title={ text.print.title }        details={ text.print.details }             />
        <Card Img={ Presentation }  title={ text.presentation.title } details={ text.presentation.details }      />
      </div>
      <div className='lg:flex lg:space-x-4 lg:mt-12 lg:justify-around lg:mx-64' >
        <Card Img={ Printer } title={ '7.195' } details={ 'Piezas impresas en 3D' } />
        <Card Img={ Filament } title={ '893' } details={ 'Kilos de filamento usado' } />

      </div>
    </section>
  )
}

export default Services
