import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { authReducer } from '../reducers/authReducer';
import { blogReducer } from '../reducers/blogReducer';

import { instagramReducer } from '../reducers/instagramReducer'

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
  instagram: instagramReducer,
  auth: authReducer,
  blogs: blogReducer,
  
})

export const store = createStore( 
  reducers,
  composeEnhancers(
    applyMiddleware( thunk )
  )
)