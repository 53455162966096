import { firebase, googleAuthProvider } from '../components/Firebase/firebase'
import { types } from '../types'


export const startGoogleLogin = () => {
  return ( dispatch ) => {
    firebase.auth().signInWithPopup( googleAuthProvider )
      .then( ({ user }) => {
        dispatch(
          login(user.uid, user.displayName, user.photoURL, user.email)
        )
      } )
  }
}

export const login = (uid, displayName, photoUrl, email) => ({
  type: types.login,
  payload: {
      uid,
      displayName,
      photoUrl,
      email,
  }
})

export const startLogout = () => {
  return async( dispatch ) => {
      await firebase.auth().signOut();

      dispatch( logout() );
  }
}

export const logout = () => ({
  type: types.logout
})