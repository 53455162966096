import { types } from "../types";
import { db } from "../components/Firebase/firebase";
import moment from 'moment'
import 'moment/locale/es';

export const blogReader = (blogs) => ({
  type: types.blogReader,
  payload: blogs
})

export const blogError = (error) => ({
  type: types.blogError,
  payload: error
})

const getBlogReaderFirebase = (dispatch, isClient) => {
  let blogs = []
  db.collection('blog').orderBy('date').get()
  .then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      let data = doc.data()
      let pushBlog = true
      if (!!isClient){
        if (moment().diff(moment(data.date.toDate())) < 0 ) {
          // menor a 0 para que entre al if y no le haga push
          pushBlog = false
        }
      }
      if (pushBlog) {
        blogs.push({
          text: data.text,
          date: data.date.toDate(),
          title: data.title,
          subTitle: data.subTitle,
          id: data.id,
        })
      }
      
    })
    blogs.sort((kv1, kv2) => {
      return kv2.date - kv1.date
    })

    dispatch( blogReader( blogs ) )
  })
  .catch((err) => {
    if (String(err).search('permissions') !== -1) {
      dispatch( blogError({error: true}) )
    }
  })
}

export const actionGetBlogReader = (isClient=false) => {
  return (dispatch) => {
    const blogStorage = JSON.parse(localStorage.getItem('blog')) || null
    if (!blogStorage) { // no hay datos en localstorage
      getBlogReaderFirebase(dispatch, isClient)
    } else { // hay datos en localstorage
      // const datetime = new Date()
      // const day = datetime.getDate()
      // const month = datetime.getMonth()
      // const expiry = String(blogStorage.expiry).split('-')
      // if ( month === parseInt(expiry[0]) && day === parseInt(expiry[1]) ) {
      //   // estoy en el mismo dia de la captura
      //   dispatch( setInstagram(blogStorage.instagram) )
      // } else {
      //   // estoy en diferente dia
      //   getInstagramFirebase(dispatch)
      // }
    }
    
  }
}

export const actionUpdateBlogReader = ({ blogs, blogLocal, index }) => {
  return (dispatch) => {
    const blogsObj = blogs.blogs
    blogsObj.splice(index, 1, blogLocal)
    dispatch( blogReader( blogsObj ) )
  }
}

export const actionDeleteBlogReader = ({ blogs, index }) => {
  return (dispatch) => {
    const blogsObj = blogs.blogs
    blogsObj.splice(index, 1)
    dispatch( blogReader( blogsObj ) )
  }
}