import React, { useState } from 'react'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import { atomOneDarkReasonable as styleCode } from 'react-syntax-highlighter/dist/esm/styles/hljs';

SyntaxHighlighter.registerLanguage('python', python);
const Pre = ({ children, ...props }) => {
  let data = {}
  try {
    data = JSON.parse(children.props.children)
  } catch {
  }

  const [toShow, setToShow] = useState( !data.hasOwnProperty('show') )
  const [notFirstShow, setNotFirstShow] = useState( !toShow )

  const handleShow = () => {
    setToShow( !toShow )
    setNotFirstShow( true )
  }

  return (
    <div className='flex flex-col'>
      <div className='flex justify-end'>
        <button className='px-2 py-1 mt-8 -mb-6 font-semibold text-teal-700 bg-transparent border border-teal-500 rounded focus:outline-none hover:bg-teal-500 hover:text-white hover:border-transparent'
        onClick={ () => handleShow() }>{ !toShow ? 'Mostrar código' : 'Ocultar código' }</button>
      </div>
      
      <div className={`mt-8 animate__animated ${toShow ? 'animate__fadeIn' : notFirstShow ? 'animate__fadeOut' : 'hidden'}`}>
        {
          data.hasOwnProperty('source') && toShow &&
          <SyntaxHighlighter language='python' 
            style={children.props.className === 'lang-python' ? styleCode : ''} 
            showLineNumbers={ children.props.className === 'lang-python' }
            showInlineLineNumbers={ children.props.className === 'lang-python' }>
            {data.source.join('')}
          </SyntaxHighlighter>
        }
      </div>
    </div>
  )
}

export default Pre


