import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/performance'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMNT_ID
}

firebase.initializeApp(config)
const db = firebase.firestore()
const storage = firebase.storage()
const storageRef = storage.ref()
const perf = firebase.performance()
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
const analytics = firebase.analytics()

export {
  firebase,
  db,
  storageRef,
  storage,
  perf,
  googleAuthProvider,
  analytics,

}