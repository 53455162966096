/* eslint-disable jsx-a11y/alt-text */

import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const Img = ({ children, ...props }) => (
  <div className='mt-8'>
    <Zoom>
      <img {...props}/>
    </Zoom>
  </div>
)


export default Img
