import React from 'react'

import ShowPerson from '../BlogAdmin/Login/ShowPerson';
import getTimeOfRead from '../getTimeOfRead';
import ErrorBoundary from './ErrorBoundary';
import MarkdownCore from './MarkdownCore'

const MarkdownCompiler = ({ isEditing, title, subTitle, textForMarkdown, date, setSaveText, setTextForMarkdown }) => {
  return (
    <>
      {
        !!textForMarkdown &&
          <div className='max-w-2xl mx-auto'>
            <h1 className='mt-2 -mb-1 font-serif text-5xl text-gray-900 leading-15'>{ title }</h1>
            <h2 className='mt-3 -mb-2 text-2xl font-light leading-8 text-gray-700'>{ subTitle }</h2>
            <ShowPerson isBlog date={ date } timeOfRead={ getTimeOfRead( textForMarkdown ) } />
            {
              !!isEditing ?
                <ErrorBoundary setSaveText={ setSaveText } setTextForMarkdown={ setTextForMarkdown } >
                  <MarkdownCore textForMarkdown={ textForMarkdown } />
                </ErrorBoundary >
              :
              <MarkdownCore textForMarkdown={ textForMarkdown } />
            }
            
      
          </div>

      }
    </>
  )
}


export default MarkdownCompiler

