import React, { useState } from 'react'
import NavBar from './NavBar'
import { animateScroll as scroll } from 'react-scroll'
import { useScrollData } from "scroll-data-hook";

const NavbarOut = ({ pages }) => {
  const [activeButton, setActiveButton] = useState(false)
  const [firstTimeButton, setfirstTimeButton] = useState(false)
  const [positionAux, setPositionAux] = useState(0)
  // const {scrolling, time, speed, direction, position, relativeDistance, totalDistance } = useScrollData()
  // https://github.com/dejorrit/scroll-data-hook
  const { position } = useScrollData()
  const handleScroll = () => {
    if (!firstTimeButton) {
      setfirstTimeButton( true )
    }
    if (activeButton) {
      scroll.scrollTo(positionAux, {duration: 0})
    } else {
      setPositionAux(position.y)
    }
  }
  
  const handleButton = () => {
    setActiveButton( !activeButton)
    handleScroll()
  }

  const closeButton = () => {
    setActiveButton(false)
    handleScroll()
    scroll.scrollToTop({
      duration: 200
    })
  }

  return (
    <nav className="fixed inset-x-0 top-0 z-50 h-16 border-b bg-backgroundNavBar">
      <div className='container max-w-screen-xl mx-auto'>
        <NavBar handleButton={ handleButton } activeButton={ false } closeButton={ closeButton } pages={ pages }  />
        <div className={`${ !activeButton ? firstTimeButton ? 'animate__slideOutRight' : 'hidden' : 'animate__slideInRight' } 
                        absolute top-0 left-0 h-screen w-screen bg-backgroundNavBar animate__animated  animate__faster md:hidden`}>
          <div className='container mx-auto'>
            <NavBar handleButton={ handleButton } activeButton={ true } closeButton={ closeButton } pages={ pages } />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavbarOut
