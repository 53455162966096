import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import './animate.output.css'
import './tailwind.output.css';

import NavBar     from './components/Navbar'
import Home       from './components/Home'
import Cotizacion from './components/Cotizacion'

import Blog, { BlogPost } from './components/Blog/Blog'

import Loadable from 'react-loadable';
import LoadMini from './components/Loading/LoadMini';

const BlogAdminRoute = Loadable({
  loader: () => import('./components/Blog/BlogAdmin/BlogAdminRoute'),
  loading: LoadMini,
  timeout: 10000, // 10 seconds
});
const Whatsapp = Loadable({
  loader: () => import('./components/Whatsapp'),
  loading: LoadMini,
  timeout: 10000, // 10 seconds
});
const Footer = Loadable({
  loader: () => import('./components/Footer'),
  loading: LoadMini,
  timeout: 10000, // 10 seconds
});

const Face3DMesh = Loadable({
  loader: () => import('./components/Face3DMesh'),
  loading: LoadMini,
  timeout: 10000, // 10 seconds
});



const HandlePages = ({ pages, Page }) => {
  return (
    <>
      <Page />
      <Whatsapp />
      <Footer pages={ pages } />
    </>
  )
}

function App() {
  const pages = [
    { 'name': 'Cotizacion',
      'to':   '/cotizar' },
    { 'name': 'Noticias',
      'to':   '/noticias',},
    // { 'name': 'Cotizacion',
    //   'to':   '/cotizar' },
    // { 'name': 'Cotizacion',
    //   'to':   '/cotizar' },
    
  ]

  return (
    <Provider store={ store }>
      <Router >
        <div className=''>
          <NavBar pages={ pages } />
          <div className='mt-16'>
            <Switch>
              <Route exact path='/'>
                <div className='mt-24 '>
                  <HandlePages pages={ pages } Page={Home} />
                </div>
              </Route>
              <Route path='/cotizar' >
                <div className='mt-24 '>
                  <HandlePages pages={ pages } Page={Cotizacion} />
                </div>
              </Route>

              <Route path='/face-to-obj' >
                <Face3DMesh />
                <Footer pages={ pages } />
              </Route>

              <Route path='/noticias/:title'>
                <HandlePages pages={ pages } Page={BlogPost} />
              </Route>

              <Route path='/noticias'>
                <HandlePages pages={ pages } Page={Blog} />
              </Route>

              <Route path='/__blog'>
                <BlogAdminRoute/>
              </Route>

              <Redirect to='/' />

            </Switch>
          </div>
        </div>
      </Router>
    </Provider>
    
  );
}

export default App;
