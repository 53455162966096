import React, { useEffect, useState } from 'react'
import { Formik, Form }    from 'formik';
import "yup-phone";
import InputFileComponent, { useInputFile } from './InputFile'
import { ReactComponent as LoadingIcon }    from '../../imgs/loading-2.svg'

import TextInput        from './TextInput'
import TextAreaInput    from './TextAreaInput'
import OptionsInput     from './OptionsInput'
import SelectInput      from './SelectInput'
import PercentInput     from './PercentInput'
import SelectSizeFaces  from './SelectSizeFaces'
import PriceFace3DMesh  from './PriceFace3DMesh'
import { yup, yupFile } from './yupValidations'
import onSubmitForm     from './onSubmitForm'
import Alert from './Alerts';

const Cotizacion = ({ isFace3DMesh, listObj, listImagesURL , disabled, countFaces }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const FILE_SIZE = 25165824 // 24Mb -> 24 * 2 ** 20
  const SUPPORTED_FORMAT = ['obj', 'stl']
  const { field: fieldFiles, setField: setFieldFiles, meta: metaFiles, handleChange, resetValues: resetFiles } = useInputFile({ FILE_SIZE, SUPPORTED_FORMAT, isFace3DMesh })
  const INIT_VALUES = {
    firstName: '',
    lastName:  '',
    phone:     '',
    message:   '',
    email:     '',
    file:      '',
    material:  'PLA Blanco',
    quality:   '0.32mm',
    infill:    isFace3DMesh === undefined ? '0' : '15'
  }

  useEffect(() => {
    if (isFace3DMesh !== undefined) {
      setDisabledButton(disabled)
    }
  }, [disabled, isFace3DMesh])

  useEffect(() => {
    if (isFace3DMesh !== undefined) {
      let names = listImagesURL.map((listImageURL) => listImageURL[1].replace('.', '_') + '.obj')
      let objVerify = listObj.filter((objName) => objName)
      let namesVerify = names.filter((_, index) => listObj[index])

      setFieldFiles({ names: namesVerify, values: objVerify })
    }
  }, [isFace3DMesh, listImagesURL, listObj, setFieldFiles])


  return (
    <div className='container max-w-screen-xl mx-auto mb-16'>
      <div className='mx-4'>
        <Formik
          initialValues={ INIT_VALUES }
          validationSchema={ (fieldFiles.values.length !== 0 && metaFiles.error.length === 0) ? yupFile : yup }
          onSubmit={ (value, { setSubmitting, setStatus, resetForm }) => 
            onSubmitForm( value, setSubmitting, setStatus, resetForm, setLoading, setErrorSubmit, resetFiles, setSuccess, INIT_VALUES, fieldFiles, isFace3DMesh, countFaces )
          }
        >
          <Form>
            
            <TextInput          label='Nombre'         name='firstName' type='text'  placeholder='Nombre' />
            <TextInput          label='Apellido'       name='lastName'  type='text'  placeholder='Apellido' />
            <TextInput          label='Correo'         name='email'     type='email' placeholder='ejemplo@ejemplo.com' />
            <TextInput          label='Número celular' name='phone'     type='phone' placeholder='+56 9 5555 5555' />
            <TextAreaInput      label='Mensaje'        name='message'   type='text'  placeholder='Mensaje' />
            <InputFileComponent label='Seleccionar archivo/s .stl o .obj'  name='file'  type='file' 
                                field={ fieldFiles } meta={ metaFiles } handleChange={ handleChange } isFace3DMesh={ isFace3DMesh } />

            <div className={`animate__animated animate__fast ${(fieldFiles.values.length !== 0 && metaFiles.error.length === 0) ? 
              'animate__fadeInLeft block' : 'hidden'} ml-8 `} >
              <OptionsInput name='material'  />
              <SelectInput  label='Calidad de impresión'   name='quality' />
              {
                isFace3DMesh === undefined ?
                <PercentInput label='Porcentaje de relleno (infill)' name='infill' />
                :
                <>
                <SelectSizeFaces label='Altura del rostro' name='infill' />
                <PriceFace3DMesh countFaces={ countFaces } />
                </>
              }
            </div>

            <div className='flex justify-center' >
              <button disabled={ disabledButton } className={`flex justify-between px-4 py-3 my-4 font-semibold leading-tight text-center text-white bg-blue-700 rounded-lg shadow-md hover:bg-blue-600 focus:shadow-outline focus:outline-none ${ disabled ? 'cursor-not-allowed' : '' }`}
                      type='submit' >
                <span>{ loading? 'Enviando mensaje...' : 'Enviar mensaje' }</span>
                <LoadingIcon className={`w-5 h-5 ml-3 text-gray-100 fill-current animate-spin ${loading ? 'block' : 'hidden'}`} />
              </button>
            </div>
            {
              errorSubmit && <Alert isError />
            }
            {
              success && <Alert />
            }
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Cotizacion
