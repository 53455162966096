import React from 'react'
import { useField } from 'formik';

const OptionsInput = ({ ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className='mb-4'>
      <label className='block mb-2 text-sm font-bold text-gray-700'>
        Material de impresión
      </label>
      <div className='relative'>
        <select {...props} {...field} className={`w-full px-3 py-2 mx-auto leading-tight text-gray-700 border rounded 
                          shadow appearance-none focus:outline-none focus:shadow-outline bg-white
                          ${(meta.touched && meta.error) && 'border border-red-500'}`} >
          <option value='' >Seleccione Material</option>
          <option disabled>PLA</option>
          <option value='PLA Blanco'        >PLA Blanco</option>
          <option value='PLA Negro'         >PLA Negro</option>
          <option value='PLA Rojo'          >PLA Rojo</option>
          <option value='PLA Azul'          >PLA Azul</option>
          <option value='PLA Verde'         >PLA Verde</option>
          <option value='PLA Morado'        >PLA Morado</option>
          <option value='PLA Amarillo'      >PLA Amarillo</option>
          <option value='PLA Café'          >PLA Café</option>
          <option value='PLA Rosado'        >PLA Rosado</option>
          <option value='PLA Gris'          >PLA Gris</option>
          <option value='PLA Dorado'        >PLA Dorado</option>
          <option disabled>PLA Fuerte</option>
          <option value='PLA_Fuerte Negro'  >PLA Fuerte Negro</option>
          <option value='PLA_Fuerte Blanco' >PLA Fuerte Blanco</option>
          <option disabled>PETG</option>
          <option value='PETG Negro'        >PETG Negro</option>
          <option value='PETG Blanco'       >PETG Blanco</option>
        </select>
        <div className='absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none'>
          <svg className='w-4 h-4 fill-current' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path 
          d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z'/></svg>
        </div>
      </div>
      <div className={`inline-block text-red-500 animate__animated animate__fast ${(meta.touched && meta.error) ? 'animate__bounceIn' : 'hidden'}`}>
        {meta.error}
      </div>
    </div>
  )
}

export default OptionsInput
