import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Logo }  from '../../logo.svg'
import { ReactComponent as Close } from '../../imgs/close-x.svg'
import { ReactComponent as Menu }  from '../../imgs/menu.svg'
import { NavLink } from 'react-router-dom'

const NavBar = ({ handleButton, activeButton, closeButton, pages }) => {

  return (
    <div className="relative w-full">
      <div className="flex items-center justify-between px-4 mt-4">
        <NavLink  to='/' exact className='pb-2 -mt-2' activeClassName='border-b border-yellow-600'
                  onClick={ closeButton } >
          <Logo alt='logo' className='h-12' />
        </NavLink>
        <button onClick={ handleButton } type='button' className='block text-gray-700 md:hidden focus:outline-none'>
          {
            activeButton ?
                <Close alt='Close navigator responsive' className='w-6 h-6 fill-current' />
              :
                <Menu alt='Menu navigator responsive' className='w-6 h-6 fill-current' />
          }
        </button>
        <div className='hidden md:flex'>
          {
            pages.map( (page) => (
              <NavLink  to={ page.to } key={ page.name }
                className={`${page.twoLines ? 'w-32 h-12 mt-0' : 'w-24 h-8 mt-4'} text-center text-gray-600`}
                activeClassName='text-blue-800 border-b border-yellow-600 font-semibold'
                onClick={ closeButton } >
                <span className='mx-auto'>{ page.name }</span>
              </NavLink>
            ) )
          }
          {/* <NavLink  to='/sobre'
                    className="w-40 h-8 mt-4 text-center text-gray-600 "
                    activeClassName='text-blue-800 border-b border-yellow-600 font-semibold'
                    onClick={ closeButton } >
                    <span className='mx-auto'>Sobre Nosotros</span>
          </NavLink>
          <NavLink  to='/contacto'
                    className="w-24 h-8 mt-4 text-center text-gray-600"
                    activeClassName='text-blue-800 border-b border-yellow-600 font-semibold'
                    onClick={ closeButton } >
                    <span className='mx-auto'>Contacto</span>
          </NavLink> */}

        </div>
      </div>
      <div className={`md:hidden ${ activeButton ? 'block' : 'hidden' } mt-10`}>
        <ul>
          <li className='mb-6'>
            {
              pages.map( (page) => (
                <NavLink  to={ page.to } key={ page.name }
                  className="block h-10 px-6 pt-2 pl-2 mx-6 text-gray-600 align-middle rounded"
                  activeClassName='text-blue-800 bg-blue-200 font-semibold'
                  onClick={ closeButton } >
                  <span className='mx-auto'>{ page.name }</span>
                </NavLink>
              ) )
            }
          </li>
        </ul>

      </div>
    </div>
  )
}

NavBar.propTypes = {
  handleButton: PropTypes.func.isRequired, 
  activeButton: PropTypes.bool.isRequired
}

export default NavBar
