import React from 'react'
import PropTypes from 'prop-types'

const Card = ({ Img, title, details, marginBot }) => {
  return (
    <div className='my-16 lg:max-w-sm lg:my-0'>
      <Img className={`w-16 h-auto text-yellow-600 fill-current ${ marginBot && '-mb-4' } `} />
      <h2 className='text-2xl font-semibold text-gray-700'>{ title }</h2>
      <span className='text-sm text-gray-600 '>{ details }</span>
    </div>
  )
}

Card.propTypes = {
  Img:       PropTypes.elementType.isRequired, 
  title:     PropTypes.string.isRequired, 
  details:   PropTypes.string.isRequired, 
  marginBot: PropTypes.bool
}

export default Card
