import { useState } from "react"

export const useInputFile = ({ SUPPORTED_FORMAT, FILE_SIZE, noSuggestion, isFace3DMesh }) => {
  const [field, setField] = useState({ names: [], values: [] })
  const [meta, setMeta] = useState({ touched: false, error: [], errorFiles: [] })
  
  const handleChange = (e) => {
    const files = Array.from(e.target.files)
    const names = []
    let listErrorFile = []
    let listErrorExtension = []
    let errorFiles = []
    if (files.length !== 0) {
      let sizeSum = 0
      for (let file of files) {
        names.push(file.name)
        sizeSum += file.size
        const extension = file.name.split('.').slice(-1)[0]
        if (!SUPPORTED_FORMAT.includes(extension)) { 
          listErrorExtension.push(extension)
          errorFiles.push(true)
        } else {
          errorFiles.push(false)
        }
      }
      if (sizeSum > FILE_SIZE && isFace3DMesh === undefined) {
        listErrorFile.push(`El tamaño total no debe exceder 24Mb (${Number(sizeSum / 1048576).toFixed(1)}Mb).${!!!noSuggestion ? ' Si el archivo excede los 24Mb porfavor adjunte un link de Google drive con el archivo en el campo de Mensaje' : ''}`)
      }
    }
    if (listErrorExtension.length !== 0) {
      listErrorExtension = Array.from(new Set(listErrorExtension))
      let outputErrorExtension = listErrorExtension.length > 1 ? 'Las extensiones ' : 'La extensión '
      for (const ext of listErrorExtension) {
        outputErrorExtension = outputErrorExtension.concat(ext, ', ')
      }
      outputErrorExtension = outputErrorExtension.slice(0, -2)
      let finishMessage = ` no está${(listErrorExtension.length > 1) ? 'n' : ''} permitida${(listErrorExtension.length > 1) ? 's' : ''}, `
      finishMessage = finishMessage.concat(`porfavor inserte sólo archivos de tipo `)
      for (const extAllow of SUPPORTED_FORMAT) {
        finishMessage = finishMessage.concat(extAllow, ' o ')
      }
      finishMessage = finishMessage.slice(0, -3)
      outputErrorExtension = outputErrorExtension.concat(finishMessage, '.')
      listErrorFile.push(outputErrorExtension)
    }
    
    setMeta({ touched: true, error: listErrorFile, errorFiles})
    setField({ names,  values: files })
  }

  const resetValues = () => {
    setField({ names: [], values: [] })
    setMeta({ touched: false, error: [], errorFiles: [] })
  }

  return {
    field,
    meta,
    handleChange,
    resetValues,
    setField
  }
}