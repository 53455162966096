import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'

const PriceFace3DMesh = ({ countFaces }) => {
  const { values,  } = useFormikContext()
  const [numFaces, setNumFaces] = useState(0)
  const [multiplyQuality, setMultiplyQuality] = useState(1)
  const [multiplyMaterial, setMultiplyMaterial] = useState(1)
  const [multiplyHeightFace, setMultiplyHeightFace] = useState(values.infill / 10)

  useEffect(() => {
    if (values.quality === '0.32mm') {
      setMultiplyQuality(1)
    } else if (values.quality === '0.2mm'){
      setMultiplyQuality(2)
    } else if (values.quality === '0.12mm') {
      setMultiplyQuality(4)
    }
  }, [values.quality])

  useEffect(() => {
    setMultiplyHeightFace( values.infill * .05 + .5 )
  }, [values.infill])

  useEffect(() => {
    let material = values.material.split(' ')
    if (material[0] === 'PLA') {
      setMultiplyMaterial(1)
    } else if (material[0] === 'PETG') {
      setMultiplyMaterial(4)
    } else {
      // PLA fuerte
      setMultiplyMaterial(2)
    }
  }, [values.material])

  useEffect(() => {
    setNumFaces(countFaces.reduce((a, b) => a + b, 0))
  }, [countFaces])

  return (
    <div className='mb-4'>
      <label className='block mb-2 text-sm font-bold text-gray-700'>
        Precio estimativo: ${ (multiplyMaterial * multiplyHeightFace * numFaces * 10000 * multiplyQuality).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&.').slice(0, -2) }.- pesos chilenos.
      </label>
    </div>
  )
}

export default PriceFace3DMesh
