import React from 'react'
import Intro    from './Intro'
import Covid    from './Covid'
import Services from './Services'
import Loadable from 'react-loadable';
import LoadMini from '../Loading/LoadMini';

const Projects = Loadable({
  loader: () => import('./Projects'),
  loading: LoadMini,
  timeout: 10000, // 10 seconds
})
const Cotiza = Loadable({
  loader: () => import('./Cotiza'),
  loading: LoadMini,
  timeout: 10000, // 10 seconds
})
const Clientes = Loadable({
  loader: () => import('./Clientes'),
  loading: LoadMini,
  timeout: 10000, // 10 seconds
})
const Face3DMeshIntro = Loadable({
  loader: () => import('./Face3DMeshIntro'),
  loading: LoadMini,
  timeout: 10000, // 10 seconds
})

const Home = () => {
  return (
    <>
      <div className='container max-w-screen-xl mx-auto'>
        <Intro />
      </div>
      <div className='my-8 border-t border-gray-200 '>
        <div className='container max-w-screen-xl mx-auto'>
          <Face3DMeshIntro />
        </div>
      </div>
      <div className='my-8 border-t border-b border-gray-200 '>
        <div className='container max-w-screen-xl mx-auto'>
          <Covid />
        </div>
      </div>
      <div className='container max-w-screen-xl mx-auto'>
        <Services />
      </div>
      <div className='mt-8 border-t border-gray-200'>
        <div className='container max-w-screen-xl mx-auto'>
          <Projects />
        </div>
      </div>
      <div className='mt-8 border-t border-gray-200'>
        <div className='container max-w-screen-xl mx-auto'>
          <Cotiza />
        </div>
      </div>
      <div className='mt-8 border-t border-gray-200'>
        <div className='container max-w-screen-xl mx-auto'>
          <Clientes />
        </div>
      </div>
    </>
    )
}

Home.propTypes = {

}

export default Home
