import React from 'react'
import { useField } from 'formik';

const SelectSizeFaces = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className='mb-4'>
      <label className='block mb-2 text-sm font-bold text-gray-700'>
        {label}
      </label>
      <div className='flex flex-col'>
        <div className='flex justify-between'>
          <div className='w-full'>
            <input className='w-full' type='range' list='tickmarks' min='10' max='30' {...field} {...props} />
            <datalist id='tickmarks'>
              <option value='10'/>
              <option value='12' />
              <option value='14' />
              <option value='16' />
              <option value='18' />
              <option value='20' />
              <option value='22' />
              <option value='24' />
              <option value='26' />
              <option value='28' />
              <option value='30'/>
            </datalist>
          </div>
          <div className='w-16'>
            <p className='text-sm text-right text-gray-700' >{ field.value }cm</p>
          </div>
        </div>
        <div className='flex items-start justify-between'>
          <span className='w-5/12 text-sm text-left text-gray-500'>Nuestros experimentos demuestran que el minimo es de 10cm para apreciar los razgos faciales.</span>
          <span className='w-5/12 text-sm text-left text-gray-500'>Un rostro adulto tiene una altura de 19 cm en promedio.</span>
        </div>
      </div>
      <div className={`inline-block text-red-500 animate__animated animate__fast ${(meta.touched && meta.error) ? 'animate__bounceIn' : 'hidden'}`}>
        {meta.error}
      </div>
    </div>
  )
}

export default SelectSizeFaces
