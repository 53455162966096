import * as Yup from "yup";

const yupBase = {
  firstName: Yup.string()
    .required('Porfavor ingrese su nombre.'),
  lastName: Yup.string()
    .required('Porfavor ingrese su apellido.'),
  email: Yup.string()
    .email('Correo inválido.')
    .required('Porfavor ingrese su correo.'),
  message: Yup.string()
    .min(5, 'Porfavor ingrese su mensaje.')
    .required('Porfavor ingrese su mensaje de consulta.'),
  phone: Yup.string()
    .phone(undefined, undefined, 'Número teléfonico no válido.')
    .required()
}
const yup = Yup.object({ ...yupBase })
const yupFile = Yup.object({
  ...yupBase,
  material: Yup.string()
    .oneOf([
        'PLA Blanco',
        'PLA Negro',
        'PLA Rojo',
        'PLA Azul',
        'PLA Verde',
        'PLA Morado',
        'PLA Amarillo',
        'PLA Café',
        'PLA Rosado',
        'PLA Gris',
        'PLA Dorado',
        'PLA_Fuerte Negro',
        'PLA_Fuerte Blanco',
        'PETG Negro',
        'PETG Blanco',
      ], 'Porfavor ingrese un tipo de material.')
      .required('Porfavor seleccione material.'),
  quality: Yup.string()
      .required('Porfavor seleccione una de las opciones.'),
  infill: Yup.string()
      .required('Porfavor seleccione una de las opciones.'),
})

export { yup, yupFile }
