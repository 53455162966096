import React from 'react'

import EscudoFacial from '../../imgs/escudo_facial.jpg'

const Covid = () => {
  return (
    <section className='mx-4 my-8'>
      <div className='mt-5 mb-4'>
        <h3 className='text-3xl font-semibold text-center text-blue-700 uppercase'>Covid-19</h3>

        <div className='mt-2 md:flex md:justify-between'>
          <div className='flex flex-col flex-wrap max-w-md md:flex-row'>
            <p className='py-2 text-sm text-gray-600 '>
              Ante la urgencia sanitaria que existe en nuestro país por la pandemia mundial COVID-19, y la falta de insumos de protección para el personal de salud. Tomamos la decisión de desarrollar soluciones a través de la impresión 3D.
            </p>
            <p className='py-2 text-sm text-gray-600 '>
              Por ello, junto al equipo de Plan 3D y la Fundación Kaplan hemos creado un escudo facial, el cual se basó en los mejores diseños a nivel mundial, tomando lo mejor y adaptandolo a los recursos disponible en nuestro pais.
            </p>
            <p className='py-2 text-sm text-gray-600 '>
              Así se creó el modelo de escudo facial que fue aprobado por el equipo de infectologia del hospital de Gustavo Fricket de viña del mar, al cual ya se realizó una donación de 40 unidades.
            </p>
            <p className='py-2 text-sm text-gray-600 '>
              Te invitamos a descargar el archivo, modificar y crear estas piezas.
            </p>
            <strong className='py-2 text-sm text-gray-600 '>
              JUNTOS SOMOS MAS FUERTES.
            </strong>
          </div>
          <div className='flex flex-col space-y-4 md:pl-20'>

              <a rel="noopener noreferrer" href={'https://firebasestorage.googleapis.com/v0/b/ocular-3d.appspot.com/o/escudo_facial.stl?alt=media&token=5e4414a6-036e-451f-bc40-1a343ef115bd'} download
                  className='block w-full max-w-sm px-3 py-3 my-4 font-semibold leading-tight text-center text-white bg-blue-700 rounded-lg shadow-md hover:bg-blue-600 focus:shadow-outline'>
                Descargar modelo (escudo_facial.stl 66.39Kb)
              </a>

            <img src={ EscudoFacial } className='w-full max-w-sm' alt="Escudo facial"/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Covid
