import React from 'react'
// import { ReactComponent as UploadFile }  from '../../../imgs/upload_file-2.svg'

const InputFileComponent = ({ field, meta, handleChange, label, isFace3DMesh }) => {
  return (
  <div className='w-full mb-4'>
    <label className='block mb-2 text-sm font-bold text-gray-700'>
      {label}
    </label>
    <input disabled={ isFace3DMesh !== undefined } id='fileSTL' multiple className='absolute w-1 h-1 overflow-hidden opacity-0 ' style={{zIndex: -1}} type='file'
          onChange={ handleChange } />
    <label  htmlFor='fileSTL' 
            className={`block w-full px-3 py-2 mx-auto leading-tight border rounded shadow appearance-none focus:outline-none focus:shadow-outline ${isFace3DMesh === undefined ? ' cursor-pointer' : ''} ${field.names.length !== 0 ? 'text-gray-800' : 'text-gray-500'}`} >
      {/* ${(meta.touched && meta.error.length !== 0) && 'border border-red-500'} */}
      { field.names.length !== 0 ? 
        <>
          {
            field.names.map( (name, i) => (
              <div key={ name } className={`flex justify-between space-x-2 ${ meta.errorFiles[i] && 'text-red-500' }`}>
                <span className='w-4/5 overflow-hidden text-current '>
                  { name }
                </span>
                {
                  isFace3DMesh === undefined &&
                  <span className='my-auto text-current'>
                    {
                      (Number(field.values[i].size / 1048576) < 1.0) ?
                        `${Number(field.values[i].size / 1048.576).toFixed(1)}Kb`
                      :
                        `${Number(field.values[i].size / 1048576).toFixed(1)}Mb`
                    }
                  </span>
                }
              </div>
            ))
          }
        </>
        : 
          'Escoja un archivo'
        }
    </label>
    <div className={` mt-3 block text-red-500 animate__animated animate__fast ${!(meta.touched && meta.error.length !== 0) && 'hidden'}`}>
      {meta.error.map(error => (
        <div key={ error } className='block animate__animated animate__fast animate__bounceIn '>
          <div className='flex flex-row'>
            <div className='mr-2 text-red-500 ' >&bull;</div>
            <div className='w-11/12'>
              { error }

            </div>

          </div>
        </div>
      ))}
    </div>
  </div>
  )
};

export default InputFileComponent