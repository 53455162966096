import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import ReactPlayer from 'react-player/youtube'
import ReactPlayer from 'react-player/lazy'
// import { ReactComponent as Point } from '../../imgs/point.svg'

const Intro = () => {

  // const [changeSubTitle, setChangeSubTitle] = useState(true)
  const [changeSubTitle] = useState(true)

  return (
    <section className='flex flex-col mx-4 md:flex-row'>
      <div className='flex flex-col md:w-7/12 md:pr-4'>
        <span className='uppercase'>Proyectos hechos realidad</span>
        <br/>
        <div>
          <h1 className='inline mb-6 text-3xl font-medium leading-snug tracking-tight'>
            Ocular 3D te ayuda a
            <span className='font-medium text-yellow-700'> desarrollar tu proyecto</span>
          </h1>
        </div>
        <div className='h-2'></div>

        {/* <div className="relative" onClick={ () => setChangeSubTitle( !changeSubTitle ) }>
          <Point className='absolute top-0 right-0 text-red-700 fill-current' />
        </div> */}
        {
          !changeSubTitle ?
            <h1 className='my-2 leading-relaxed tracking-tight text-gray-600 text-md '>
              Queremos ser tus socios para que juntos desarrollemos tu proyecto
            </h1>
          :
            <h1 className='max-w-lg my-2 font-semibold leading-relaxed tracking-tight text-gray-600 text-md'>
              Te ayudamos a desarrollar el proyecto que estás llevando a cabo,&nbsp;
              <span className='lg:block'>
              para que puedas crear productos asombrosos más rápido.

              </span>
            </h1>
        }

        <div className="flex flex-row mt-4">
          <Link className='px-3 py-3 my-4 font-semibold leading-tight text-center text-white bg-blue-700 rounded-lg shadow-md hover:bg-blue-600 focus:shadow-outline'
                to='/cotizar'>
            Cotiza aquí
          </Link>
          {/* <Link className='px-3 py-3 my-4 ml-4 font-semibold leading-tight text-center text-blue-700 bg-white border border-blue-700 rounded-lg hover:bg-gray-200 focus:shadow-outline'
                to='/sobre'>
            ¿Porqué Ocular 3D?
          </Link> */}
        </div>
      </div>
      <div className='z-0 mt-4 md:w-5/12'>
        <div className='relative z-0 bg-gray-700' style={{paddingTop: '56.25%'}}>
          {/* <div className='w-full h-full bg-gray-700'></div> */}
          {/* <iframe title='' width="560" height="315" src="https://www.youtube.com/embed/4wQOvyN5QoM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          <ReactPlayer className='absolute top-0 left-0 ' url='https://youtu.be/4wQOvyN5QoM' width='100%' height='100%' />
        </div>
      </div>
    </section>
    )
}


export default Intro
