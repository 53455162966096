import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLogout } from '../../../../actions/actionAuth'

const ShowPerson = ({ noMargin, isBlog, date, timeOfRead }) => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch( startLogout() )
  }
  if (!!isBlog){
    return (
      <div className='block my-10'>
        <div className='flex items-center '>
          <img className='w-12 h-12 mr-4 rounded-full' src='https://firebasestorage.googleapis.com/v0/b/ocular-3d.appspot.com/o/logo-cut.png?alt=media&token=a6d3ae40-37d6-41ee-8fe2-8b171d2f2e8c' 
            alt='logo' title='logo del equipo' />
          <div className='text-sm'>
            <p className='leading-none text-gray-900'>Equipo de Ocular 3D</p>
            <p className='text-gray-600'>{ date } &bull; { timeOfRead } min de lectura</p>
          </div>
        </div>

      </div>
    )
  }
  return (
    <div className={`flex flex-row-reverse ${!!!noMargin && 'mt-20'} mr-8`}>
      <div className='flex items-center '>
        <img className='w-12 h-12 mr-4 rounded-full' src={ auth.photoUrl } alt='account'/>
        <div className='text-sm'>
          <p className='leading-none text-gray-900'>{ auth.name }</p>
          <p className='text-gray-600'>{ auth.email }</p>
          <button className='text-gray-600'
          onClick={ handleLogout }
          >
            Cerrar sesión
          </button>

        </div>
      </div>

    </div>
  )
}

export default ShowPerson
