import React from 'react'
import moment from 'moment'
import 'moment/locale/es';

import getCurrentPost from '../BlogAdmin/Post/getCurrentPost'
import MarkdownCompiler from '../MarkdownCompiler'
import Newsletter from './Newsletter';

const BlogPost = () => {
  const { blogLocal, FORMAT, preprocessText, existsPost } = getCurrentPost()


  return (
    <div className='mx-4 mb-16 md:mx-0 '>
      {
        Object.keys( blogLocal ).length !== 0 ?
        <>
          <MarkdownCompiler
            title={ blogLocal.title }
            subTitle={ blogLocal.subTitle }
            date={ moment(blogLocal.date).format(FORMAT) }
            textForMarkdown={ preprocessText( blogLocal.text ).concat('\n\n***\n\n') } />
          <div className='w-full max-w-2xl mx-auto mt-16'>
            <Newsletter insidePost />
          </div>
        </>
          :
        <div className='max-w-2xl mx-auto'>
          <p className='mt-12 text-4xl font-semibold leading-10 tracking-tight text-gray-900' >
            { 
              existsPost ?
                'El documento se esta cargando...'
              :
                'El documento solicitado no existe'
            }
          </p>
        </div>
      }
      
    </div>
  )
}

export default BlogPost
