import React from 'react'
import { useField } from 'formik';

const PercentInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div className='mb-4'>
      <label className='block mb-2 text-sm font-bold text-gray-700'>
        {label}
      </label>
      <div className='flex justify-between'>
        <div className='w-full'>
          <input className='w-full' type='range' list='tickmarks' {...field} {...props} />
          <datalist id='tickmarks'>
            <option value='0'  />
            <option value='10' />
            <option value='20' />
            <option value='30' />
            <option value='40' />
            <option value='50' />
            <option value='60' />
            <option value='70' />
            <option value='80' />
            <option value='90' />
            <option value='100'/>
          </datalist>

        </div>
        <div className='w-16'>
          <p className='text-sm text-right text-gray-700' >{ field.value }%</p>

        </div>
      </div>
      <div className={`inline-block text-red-500 animate__animated animate__fast ${(meta.touched && meta.error) ? 'animate__bounceIn' : 'hidden'}`}>
        {meta.error}
      </div>
    </div>
  )
}

export default PercentInput
